import React, { Suspense } from "react"
import { BrowserRouter, Route, Routes, useNavigate, useParams } from "react-router-dom"
import SimpleLoadingOverlay from "./components/SimpleLoadingOverlay"
import { EnvironmentContainer } from "./controllers/Environment"
import ProductByUid from "./loaders/ProductByUid"
import ProductByRid from "./loaders/ProductByRid"
import SkuByBatch from "./loaders/SkuByBatch"
import SkuByIdOrGtin from "./loaders/SkuByIdOrGtin"
import Demo from "./pages/Demo"
import { GtinRouter } from "./components/gtin/GtinRouter"

const Scanner = React.lazy(() => import("./pages/Scanner"))
const ClosedPage = React.lazy(() => import("./pages/ClosedPage"))
const DownloadPage = React.lazy(() => import("./pages/DownloadConsumerAppPage"))

const StagingRedirect = () => {
  const Environment = EnvironmentContainer.useContainer()
  Environment.setStaging()
  return AppRoutes
}

const FreetextRedirect = () => {
  const { ignoredText } = useParams()
  console.log(`ignored path: /${ignoredText}/`)
  return AppRoutes
}

// Navigation Example
const Page1 = () => {
  const navigate = useNavigate()
  const Environment = EnvironmentContainer.useContainer()

  function handleClick() {
    navigate("/example-page-2")
  }

  return (
    <>
      <h1>Page 1</h1>
      <h2>Branch: {Environment.ENV}</h2>
      <h2>Querying: {Environment.getQueryEndpoint()}</h2>
      <button onClick={handleClick}>Go to Page 2</button>
    </>
  )
}
const Page2 = () => <h1>Page 2: {EnvironmentContainer.useContainer().ENV}</h1>

const AppRoutes = (
  <Routes>
    <Route path='/example-page-1' element={<Page1 />} />
    <Route path='/example-page-2' element={<Page2 />} />

    <Route path='/download' element={<DownloadPage />} />
    {/* <Route path='/p/:uid' element={<LandingPageByUidOrBatch />} /> */}
    <Route path='/p/:uid' element={<ProductByUid />} />
    <Route path='/r/:rid' element={<ProductByRid />} />
    <Route path='/b/:batch' element={<SkuByBatch />} />
    <Route path='/s/:id' element={<SkuByIdOrGtin />} />
    <Route path='/01/:gtin/*' element={<GtinRouter />} />
    <Route path='/g/*' element={<GtinRouter />} />

    <Route path='/scan/:uid' element={<Scanner />} />
    <Route path='/scan' element={<Scanner />} />
    <Route path='/closed' element={<ClosedPage />} />
    <Route path='/demo' element={<Demo />} />

    <Route path='/staging/*' element={<StagingRedirect />} />
    <Route path='/:ignoredText/*' element={<FreetextRedirect />} />

    <Route path='*' element={<Scanner />} />
  </Routes>
)

const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<SimpleLoadingOverlay />}>{AppRoutes}</Suspense>
    </BrowserRouter>
  )
}

export default Router
