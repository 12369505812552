import { init as InitAmplitude } from "@amplitude/analytics-browser"
import { ApolloClient, ApolloProvider, defaultDataIdFromObject, HttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { EnvironmentContainer } from "./controllers/Environment"
import { PopupContainer } from "./controllers/PopupManager"
import DevFlag from "./assets/images/DevFlag.png"
import Router from "./Router"
import { Helmet } from "react-helmet"
import createUploadLink from "apollo-upload-client/createUploadLink.mjs"



//import { Environment } from './Settings';

function App() {
  const Environment = EnvironmentContainer.useContainer()
  const stagingOverlay = Environment.isStaging() ? (
    <img
      alt='dev flag'
      src={DevFlag}
      style={{
        pointerEvents: "none",
        zIndex: 9999,
        position: "fixed",
        maxWidth: "40%",
      }}
    />
  ) : undefined
  const apiKey = Environment.isStaging() ? Environment.AmplitudeApiKey : Environment.AmplitudeProdKey

  const authLink = setContext((_, { headers }) => {
    const tokenString = localStorage.getItem("jwt")
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: tokenString ? `${tokenString}` : "",
      },
    }
  })
  const httpLink = new HttpLink({
    uri: Environment.getQueryEndpoint(),
  })
  const apolloClient = new ApolloClient({
    // TODO: Issue #78 - The following 2 lines are were switched when moving to temporary upload component, switch back when reverting to old component
    // link: authLink.concat(httpLink),
    link: authLink.concat(createUploadLink({ uri: Environment.getQueryEndpoint() })),
    cache: new InMemoryCache({
      dataIdFromObject: (obj) => {
        const id = defaultDataIdFromObject(obj)
        if (!id) {
          const { __typename: typename } = obj
          switch (typename) {
            case "File":
              return `${typename}:${obj.url}` // return 'url' as it is unique

            default:
              return id
          }
        }
      },
    }),
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  })

  InitAmplitude(apiKey, undefined)

  return (
    <ApolloProvider client={apolloClient}>
      <>
        {stagingOverlay}
        {PopupContainer.useContainer().popup}
        <Router />
      </>
    </ApolloProvider>
  )
}

export default App
