import React from "react"
import { createContainer } from "unstated-next"
import { FirebaseApp, initializeApp } from "firebase/app"
import { fetchAndActivate, getRemoteConfig, RemoteConfig } from "firebase/remote-config"

export const LAST_CLOSE_KEY = "last_close"
export const DOWNLOAD_APP_LINK = "https://orijinplusdownload.page.link/get-in-app"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseJson = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
  appId: process.env.REACT_APP_FIREBASE_APP_ID!,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID!,
}

const defaultConfig = {
  landing_scan_now_fallback_gif: "https://assets.orijin.plus/tear_off_animation.gif",
  landing_scan_now_fallback_video: "https://assets.orijin.plus/tear_off_animation.mp4",
  landing_scan_now_button_text: "SCAN HIDDEN QR NOW",
  landing_scan_now_button_enabled: false,
  landing_close_now_gif: "https://assets.orijin.plus/one-code-close-now.gif",
  landing_close_now_button_text: "CLOSE NOW",
  landing_page_header: `AUTHENTICATE
    EARN, WIN`,
  landing_page_preactivation_image: "https://assets.orijin.plus/preactivation-long-image.png",

  no_scan_error_delay: 12,
  no_scan_error_header: "Looks like we're having trouble scanning!",
  no_scan_error_message: "Try using your camera app instead.",
  no_scan_error_button_text: "TAKE PHOTO",

  repeate_close_warning_header: "WARNING",
  repeate_close_warning_message: "If you already have an account you must use the mobile app to claim any more points!",
  repeate_close_warning_button_text: "Download Orijin Plus Now",

  failed_photo_scan_warning_header: "WARNING",
  failed_photo_scan_warning_message: "Seems the image was not clear enough, can you please try again.",

  closed_page_prize_popup_delay: 5,
  closed_page_show_popup_multiple_times: false,
}

const Environment = () => {
  const productionBackends: string[] = ["https://api.orijin.plus/"]

  const stagingBackends: string[] = ["https://api-staging.orijin.plus/"]

  /* CONSTANTS (can't change once App is initialized) */
  const PrimaryBackend = process.env.REACT_APP_BACKEND_DOMAIN_PRIMARY!
  const StagingBackend = process.env.REACT_APP_BACKEND_DOMAIN_STAGING!
  const AmplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY!
  const AmplitudeProdKey = process.env.REACT_APP_AMPLITUDE_API_KEY_PROD!
  const GoogleApiKey = process.env.REACT_APP_GOOGLE_APIKEY!
  const CloudFunctionsApi = process.env.REACT_APP_CLOUD_FUNCTIONS_API!

  /* VARIABLES (can change during runtime) */
  const [BackendDomain, setBackendDomain] = React.useState(PrimaryBackend)
  const [innerQueryEndpoint, innerSetQueryEndpoint] = React.useState(process.env.REACT_APP_QUERY_API!)
  const [innerCloseEndpoint, innerSetCloseEndpoint] = React.useState(process.env.REACT_APP_CLOSE_ENDPOINT!)
  const [DarkMode, setDarkMode] = React.useState(false)
  const ENV: "production" | "staging" | "development" = productionBackends.find((x) => x.indexOf(BackendDomain) === 0)
    ? "production"
    : stagingBackends.find((x) => x.indexOf(BackendDomain) === 0)
      ? "staging"
      : "development"

  // Firebase Remote Config
  const [isRemoteConfigLoaded, setConfigIsRemoteLoaded] = React.useState(false)
  function configFromFirebaseApp(app: FirebaseApp): RemoteConfig {
    const config = getRemoteConfig(app)
    config.settings.minimumFetchIntervalMillis = 1000 * 15 // 15 seconds // Don't reload config if time since last fetch is less than this long ago.
    config.defaultConfig = defaultConfig
    fetchAndActivate(config).then(() => {
      setConfigIsRemoteLoaded(true)
    })
    return config
  }
  const [firebaseApp] = React.useState(initializeApp(firebaseJson))
  const [remoteConfig, setRemoteConfig] = React.useState<RemoteConfig>(configFromFirebaseApp(firebaseApp))
  React.useEffect(() => {
    setConfigIsRemoteLoaded(false)
    setRemoteConfig(configFromFirebaseApp(firebaseApp))
  }, [firebaseApp])

  // Validators
  function setStaging(active: boolean = true) {
    const value = active ? StagingBackend : PrimaryBackend
    setBackendDomain(value)
  }
  function isStaging() {
    return BackendDomain === StagingBackend
  }

  function getQueryEndpoint() {
    return BackendDomain + innerQueryEndpoint
  }
  function setQueryEndpoint(value: string) {
    let url = new URL(value, BackendDomain)
    let backend = new URL(BackendDomain)
    if (url.origin === backend.origin) innerSetQueryEndpoint(url.pathname)
    else throw new Error("query endpoint must be on backend")
  }

  function getCloseEndpoint() {
    return BackendDomain + innerCloseEndpoint
  }
  function setCloseEndpoint(value: string) {
    let url = new URL(value, BackendDomain)
    let backend = new URL(BackendDomain)
    if (url.origin === backend.origin) innerSetCloseEndpoint(url.pathname)
    else throw new Error("close endpoint must be on backend")
  }

  return {
    AmplitudeApiKey,
    AmplitudeProdKey,
    GoogleApiKey,
    CloudFunctionsApi,
    BackendDomain,
    setBackendDomain,
    setStaging,
    isStaging,
    getQueryEndpoint,
    setQueryEndpoint,
    getCloseEndpoint,
    setCloseEndpoint,
    ENV,
    DarkMode,
    setDarkMode,
    firebaseApp,
    /** Access to the remote config values.
     *
     * Note: Wait for `isRemoteConfigLoaded` to be true or expect the value may change.
     * Things like timeouts should only be started after the remote config has finished loading.
     */
    remoteConfig,
    /** Similar to `ensureInitialized(RemoteConfig)` but only returns true after remote fetch has completed. Use this to ensure the values you are fetching are from the remote server, not the default values. */
    isRemoteConfigLoaded,
  }
}

export const EnvironmentContainer = createContainer(Environment)
