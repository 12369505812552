import { useParams } from "react-router-dom"
import SkuByIdOrGtin from "../../loaders/SkuByIdOrGtin"
import ByUidOrBatch from "../../loaders/ByUidOrBatch"
import { useGs1Toolkit } from "../../helpers/GS1DigitalLinkToolkit"

export type GtinRouterProps = {
  gtin: string
}

export const GtinRouter = () => {
  const params = useParams()
  const toolkit = useGs1Toolkit()

  const gtinFromParams = params["gtin"]
  const attribStr = params["*"]
  let gString = window.location.href

  try {
    gString = toolkit.decompressGS1DigitalLink(gString, false, window.origin)
  } catch (err) {
    console.log("GS1DigitalLinkToolkit.js could not decompress this uri")
  }

  const AIs = toolkit.extractFromGS1digitalLink(gString)
  const gtin = AIs.GS1["01"]
  const batch = AIs.GS1["10"]
  const uid = AIs.GS1["235"]

  if (gtinFromParams && (!attribStr || attribStr.length === 0)) return <SkuByIdOrGtin />

  if (uid) return <ByUidOrBatch uid={uid} />

  if (batch) return <ByUidOrBatch batch={batch} />

  if (gtin) return <SkuByIdOrGtin gtin={gtin} />

  return <h1>Unable to find valid identifier in URI</h1>
}

export default GtinRouter
